<template>
  <b-modal
    id="modal-nps-feedback"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ `${$t('Adicionar feedback do NPS')} (Pedido: ${form.saleId})` }}
      </p>
    </template>

    <FormulateForm
      ref="npsFeedbackForm"
      name="npsFeedbackForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="nps_modal-description"
              v-model="form.description"
              name="description"
              class="w-100 required"
              type="textarea"
              validation="required"
              rows="4"
              :label="$t('Descrição')"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper } from '@/mixins'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [formulateHelper],

  data() {
    return {
      busy: false,
      form: {
        saleId: null,
        description: null,
      },
    }
  },

  computed: {},

  methods: {
    async onConfirm() {
      try {
        this.$refs.npsFeedbackForm.showErrors()
        if (this.$refs.npsFeedbackForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }
        this.busy = true
        const body = this.form

        await this.$http.put('/api/sales/add-nps-feedback', body)
        this.$emit('after-confirm', body)

        this.onHideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    resetForm() {
      this.form = {
        saleId: null,
        description: null,
      }
    },

    onHideModal() {
      this.$bvModal.hide('modal-nps-feedback')
    },

    onHideActions() {
      if (this.modalReject) this.modalReject({ message: 'Autorização cancelado' })
    },

    show(saleId) {
      this.$bvModal.show('modal-nps-feedback')
      this.resetForm()
      this.form.saleId = saleId
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#nps_modal-description')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
